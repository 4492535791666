import React from "react";
import { graphql } from 'gatsby';
import Layout from "../layouts/layout";
import { renderEmdAssetBlock } from "../utils";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const CaseStudy = ({ data: { pageData } }) => {

    const hero = {};
 
    hero.heroHeading = pageData.title;
    hero.heroText = pageData.description;

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {

                const ref = renderEmdAssetBlock(pageData.content.references, node);
                return (
                    `<img className="object-cover object-center w-full h-32 block" alt="" src=${ref.fluid.src} />`
                );
            }
        }
    };

    return (

        <Layout hero={hero}>
            <div className="container mx-auto mb-16 sm:mb-32">
                <div className="sm:flex sm:w-4/5 mx-auto items-center">

                    <div className="px-8 mb-4 sm:mb-0 self-start">
                         <div className="nested-html mx-auto text-justify"
                              dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(pageData.content.raw), options) }}
                        />
                    </div>

                </div>
            </div>

        </Layout>

    )};

export const query = graphql`
    query NewsDetailQuery($slug: String!) {
      pageData: contentfulCaseStudies (slug: { eq: $slug}) {
          title
          description
          content { 
            raw
            references {
            ... on ContentfulAsset {
                    __typename
                    contentful_id
                    fluid {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
          }
       }
    }
`;


export default CaseStudy;
